<template>
  <b-modal
    size="md"
    centered
    header-class="px-4 px-md-4 pt-4 pb-0 border-bottom-0"
    lazy
    hide-footer
    body-class="px-4 pt-0 pb-4"
    :id="modalId"
  >
    <template v-if="isActive">
      <header class="text-center mb-4 px-6">
        <h3 class="tlw-font-bold tlw-text-center tlw-text-xl tlw-mb-1">
          {{
            $t(
              `sparkmembers.members-details.products.components.edit-enrollment-modal.header.${enrollment.status}.title`
            )
          }}
        </h3>
        <p v-if="enrollment.status === 'deactivated'">
          {{
            $t(
              `sparkmembers.members-details.products.components.edit-enrollment-modal.header.${
                enrollment.status
              }.subtitle`
            )
          }}
        </p>
      </header>
      <hs-group>
        <span class="font-size-sm">{{
          $t(`sparkmembers.members-details.products.components.edit-enrollment-modal.form.title`)
        }}</span>
        <p class="font-size-sm font-weight-bold">{{ enrollment.school_product.title }}</p>
        <div class="d-flex justify-content-between mt-4">
          <span class="font-size-sm expired-text">
            {{ statusText }}
          </span>
          <hs-badge pill :variant="enrollment.status === 'active' ? 'success' : 'danger'" class="py-1 px-2">
            {{
              $t(
                `sparkmembers.members-details.products.components.edit-enrollment-modal.form.status.${
                  enrollment.status
                }`
              )
            }}
          </hs-badge>
        </div>
        <p class="font-size-sm mt-4">
          {{ $t(`sparkmembers.members-details.products.components.edit-enrollment-modal.form.description`) }}
        </p>
      </hs-group>
      <hs-group>
        <hs-radio v-model="available_time_type" name="availability" value="unlimited">
          {{ $t(`sparkmembers.members-details.products.components.edit-enrollment-modal.form.radio.unlimited`) }}
        </hs-radio>
      </hs-group>
      <hs-group>
        <hs-radio class="mt-2" v-model="available_time_type" name="availability" value="fixed_date">
          {{ $t(`sparkmembers.members-details.products.components.edit-enrollment-modal.form.radio.fixed_date`) }}
        </hs-radio>
        <div class="pl-4 mt-2">
          <hs-date
            id="date"
            v-model="expireDate"
            :label-no-date-selected="$t('date.format')"
            :disabled="available_time_type !== 'fixed_date'"
            :min="new Date()"
            value-as-date
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          >
            <template slot="button-content">
              <hs-icon variant="regular" icon="calendar-alt"></hs-icon></template
          ></hs-date>
        </div>
      </hs-group>
      <div
        :class="{ 'd-flex justify-content-between flex-column flex-md-row': enrollment.status === 'active' }"
        class="mt-4"
      >
        <MButton
          v-if="enrollment.status === 'active'"
          type="button"
          icon="trash-alt"
          :label="$t(`sparkmembers.members-details.products.components.edit-enrollment-modal.form.btns.deactivate`)"
          variant="critical-outline"
          @click="deactivateEnrollment"
        />
        <div class="d-flex justify-content-md-end mt-3 mt-md-0">
          <MButton
            v-if="enrollment.status === 'deactivated'"
            class="mr-3"
            type="button"
            :label="$t(`sparkmembers.members-details.products.components.edit-enrollment-modal.form.btns.cancel`)"
            variant="primary-outline"
            @click="closeModal"
          />
          <MButton class="tlw-w-full md:tlw-w-auto" :label="actionLabel" variant="primary" @click="updateEnrollment" />
        </div>
      </div>
    </template>
    <template v-else>
      <hs-loading />
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import MButton from '@/shared/components/MButton.vue';
import { hsLoading } from '@/components';

export default {
  components: {
    MButton,
    hsLoading,
  },
  props: {
    modalId: String,
  },
  data() {
    return {
      isActive: false,
      available_time_type: null,
      selectedEnrollment: {},
      form: {
        activated_by_admin: null,
        unlimited: null,
        available_until: null,
        id: null,
        ignore_set_available_until: null,
        status: null,
      },
    };
  },
  created() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'edit-enrollment-modal') {
        this.isActive = true;
        this.available_time_type = this.enrollment.unlimited ? 'unlimited' : 'fixed_date';
        this.form = {
          activated_by_admin: true,
          unlimited: this.enrollment.unlimited,
          available_until: this.enrollment.available_until,
          id: this.enrollment.id,
          ignore_set_available_until: true,
          status: this.enrollment.status,
        };
      }
    });
  },
  computed: {
    ...mapState('enrollment', {
      enrollment: state => state.selectedEnrollment,
    }),
    actionLabel() {
      return this.enrollment.status === 'active'
        ? this.$t(`sparkmembers.members-details.products.components.edit-enrollment-modal.form.btns.save`)
        : this.$t(`sparkmembers.members-details.products.components.edit-enrollment-modal.form.btns.activate`);
    },
    statusText() {
      return this.enrollment.status === 'active'
        ? this.$t('sparkmembers.members-details.products.components.edit-enrollment-modal.form.created', {
            date: this.date(this.enrollment.created_at),
          })
        : this.$t('sparkmembers.members-details.products.components.edit-enrollment-modal.form.expires', {
            date: this.date(this.enrollment.deactivated_at),
          });
    },
    expireDate: {
      get() {
        return this.form.available_until ? dayjs(this.form.available_until).toDate() : null;
      },
      set(newDate) {
        this.form.available_until = newDate.toISOString();
      },
    },
  },
  methods: {
    date(value) {
      return dayjs(value).format('DD/MM/YYYY');
    },
    closeModal() {
      this.$emit('onCloseModal', this.modalId);
    },
    updateEnrollment() {
      this.$emit('updateEnrollment', {
        ...this.form,
        unlimited: this.available_time_type === 'unlimited',
        available_until: this.available_time_type === 'fixed_date' ? this.form.available_until : null,
        status: 'active',
      });
    },
    deactivateEnrollment() {
      this.$emit('onShowDeactivateModal', {
        ...this.form,
        unlimited: true,
        available_until: null,
        status: 'deactivated',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.expired-text {
  color: $color-neutral-600;
}
/deep/ #edit-enrollment-modal {
  .custom-control-label {
    font-size: 0.875rem;
    line-height: 1.45rem;
  }
  .custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: $purple-dark;
    background-color: $purple-dark;
  }
  .custom-control-input:hover ~ .custom-control-label:before {
    border-color: $purple-dark !important;
  }
  .custom-control-input:not(:focus) ~ .custom-control-label:before {
    border: 1px solid #adb5bd;
  }
  .custom-control-input:active ~ .custom-control-label:before {
    background-color: transparent;
  }
  .custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: $purple-dark;
    background-color: $purple-dark;
  }
}
</style>
