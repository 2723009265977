<template>
  <div class="row m-0">
    <div
      class="col-12 col-lg-6 my-3 px-0"
      v-for="(enrollment, index) in enrollmentsData"
      :key="`enrollment_${index}`"
      :class="index % 2 === 0 ? 'pr-md-3' : 'pl-md-3'"
    >
      <CardEnrollment
        :loading="isLoading"
        :progress="enrollment.progress_info ? enrollment.progress_info.completed : 0"
        :enrollment="enrollment"
        @onShowEditEnrollmentModal="showEditEnrollmentModal"
      />
    </div>
    <div class="col-12">
      <div v-if="enrollmentsData.length < total" class="d-flex flex-fill flex-column justify-content-end">
        <MButton
          :label="btnLabel"
          variant="link"
          size="md"
          :icon="loadingEnrollments ? 'circle-notch fa-spin' : null"
          :disabled="loadingEnrollments"
          @click.prevent="currentPage += 1"
          class="underline--hover font-size-sm align-self-center"
        />
      </div>
    </div>

    <EditEnrollmentModal
      modal-id="edit-enrollment-modal"
      @onCloseModal="closeModal"
      @updateEnrollment="updateEnrollment"
      @onShowDeactivateModal="showDeactivateModal"
    />

    <DeactivateEnrollmentModal
      modal-id="deactivate-enrollment-modal"
      @confirmUpdateEnrollment="confirmUpdateEnrollment"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { enrollmentService } from '@/services';
import CardEnrollment from '@/sparkmembers/views/MemberDetail/components/CardEnrollment';
import EditEnrollmentModal from './components/EditEnrollmentModal';
import DeactivateEnrollmentModal from './components/DeactivateEnrollmentModal';
import ToastHelper from '@/shared/helpers/toast';

export default {
  props: {
    enrollments: Array,
    isLoading: Boolean,
    pageData: Object,
  },
  data() {
    return {
      loadingEnrollments: false,
      deactivateForm: {},
      enrollmentsData: [{}, {}, {}, {}, {}, {}],
      currentPage: 1,
      perPage: 6,
      total: 0,
    };
  },
  components: {
    CardEnrollment,
    EditEnrollmentModal,
    DeactivateEnrollmentModal,
    MButton: () => import('@/shared/components/MButton.vue'),
  },
  watch: {
    currentPage(newValue) {
      this.getEnrollments(newValue);
    },
    enrollments(newValue) {
      this.enrollmentsData = newValue;
    },
    pageData(newValue) {
      this.total = newValue.total_count;
    },
  },
  computed: {
    ...mapState('member', {
      member: state => state.selectedMember,
    }),
    btnLabel() {
      return this.isLoading ? 'Carregando...' : 'Carregar mais';
    },
  },
  methods: {
    ...mapActions('enrollment', ['selectEnrollment']),
    async getEnrollments(page, isUpdatingEnrollment = false) {
      this.loadingEnrollments = true;
      await enrollmentService
        .get([
          { key: 'student_id', value: this.member.id },
          { key: 'page', value: page },
          { key: 'per_page', value: this.perPage },
        ])
        .then(res => {
          if (isUpdatingEnrollment) {
            this.enrollmentsData = this.enrollmentsData.map(enroll => {
              const findEnrollment = res.enrollments.find(e => e.id === enroll.id);
              if (findEnrollment) {
                return findEnrollment;
              }
              return enroll;
            });
            return;
          }
          this.enrollmentsData.push(...res.enrollments);
        })
        .finally(() => {
          this.loadingEnrollments = false;
        });
    },
    showEditEnrollmentModal(enrollment) {
      this.selectEnrollment(enrollment);
      this.$bvModal.show('edit-enrollment-modal');
    },
    showDeactivateModal(form) {
      this.deactivateForm = form;
      this.closeModal('edit-enrollment-modal');
      this.$bvModal.show('deactivate-enrollment-modal');
    },
    closeModal(modalId) {
      this.$bvModal.hide(modalId);
    },
    confirmUpdateEnrollment() {
      this.updateEnrollment(this.deactivateForm);
    },
    async updateEnrollment(data) {
      try {
        await enrollmentService.updateEnrollment(data.id, data);
        ToastHelper.successMessage(
          this.$t(`sparkmembers.members-details.products.toast.update-enrollment.${data.status}.success`)
        );
      } catch (e) {
        ToastHelper.dangerMessage(
          this.$t(`sparkmembers.members-details.products.toast.update-enrollment.${data.status}.error`)
        );
      } finally {
        this.getEnrollments(this.currentPage, true);
        this.selectEnrollment({});
        this.closeModal('edit-enrollment-modal');
      }
    },
  },
};
</script>
