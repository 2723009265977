<template>
  <div class="d-flex flex-column justify-content-between bg-white h-100 rounded-lg p-4 enrollmentCard">
    <div class="d-flex flex-column">
      <template v-if="loading">
        <div class="d-flex align-items-center justify-content-between">
          <hs-placeholder animated width="100px" height="14px" />
          <hs-placeholder animated width="40px" height="12px" />
        </div>
        <hs-placeholder animated width="150px" height="14px" class="my-3" />
        <hs-placeholder animated width="80px" height="12px" />
      </template>
      <template v-else>
        <div class="d-flex align-items-center justify-content-between">
          <div class="w-50">
            <h6 class="font-weight-bold text-truncate" v-b-tooltip.hover.bottom="checkTitleSize">
              {{ enrollment.school_product.title }}
            </h6>
          </div>
          <div class="w-50 d-flex justify-content-end">
            <p class="font-size-xs mb-0 text-nowrap">
              {{ $t('sparkmembers.members-details.card-enrollment.progress', { progress: Math.floor(progress) }) }}
            </p>
          </div>
        </div>
        <div class="my-3">
          <hs-progress height="8px" :value="progress" :max="100" variant="purple" v-if="!loading" />
        </div>
        <div class="d-flex justify-content-between">
          <p class="font-size-sm mb-0">
            {{ statusText }}
          </p>
          <hs-badge pill :variant="isEnrollmentActive ? 'success' : 'danger'" class="py-1 px-2">
            {{ $t(`sparkmembers.members-details.card-enrollment.status.${enrollment.status}`) }}
          </hs-badge>
        </div>
        <div v-if="enrollment.available_until" class="mt-3">
          <p class="font-size-sm mb-0">
            {{
              $t('sparkmembers.members-details.card-enrollment.expires', {
                date: date(enrollment.available_until),
              })
            }}
          </p>
        </div>
        <div v-if="enrollment.unlimited && isEnrollmentActive" class="mt-3">
          <p class="font-size-sm mb-0">
            {{ $t('sparkmembers.members-details.card-enrollment.unlimited') }}
          </p>
        </div>
      </template>
    </div>

    <div v-if="!loading" class="d-flex flex-column flex-md-row mt-4 justify-content-between">
      <button data-testid="card-btn" @click="editEnrollment()" class="btn btn-link enrollment-action">
        <hs-icon icon="pencil" size="14" />
        <span class="font-size-sm ml-1">{{ buttonLabel }}</span>
      </button>
      <button data-testid="history-btn" @click="accessEnrollmentDetail" class="btn btn-link enrollment-action">
        <span class="font-size-sm">Histórico de Acesso</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import dayjs from 'dayjs';

export default {
  props: {
    progress: [String, Number],
    loading: Boolean,
    enrollment: Object,
  },
  computed: {
    checkTitleSize() {
      return this.enrollment.school_product.title.length > 18 ? this.enrollment.school_product.title : false;
    },
    buttonLabel() {
      return this.isEnrollmentActive
        ? this.$t(`sparkmembers.members-details.card-enrollment.action-button.label.edit`)
        : this.$t(`sparkmembers.members-details.card-enrollment.action-button.label.activate`);
    },
    isEnrollmentActive() {
      return this.enrollment.status === 'active';
    },
    statusText() {
      return this.isEnrollmentActive
        ? this.$t('sparkmembers.members-details.card-enrollment.created_at', {
            date: this.date(this.enrollment.created_at),
          })
        : this.$t('sparkmembers.members-details.card-enrollment.deactivated_at', {
            date: this.date(this.enrollment.deactivated_at || this.enrollment.updated_at),
          });
    },
  },
  methods: {
    ...mapActions('enrollment', ['selectEnrollment']),
    date(value) {
      return dayjs(value).format('DD/MM/YYYY');
    },
    editEnrollment() {
      this.$emit('onShowEditEnrollmentModal', this.enrollment);
    },
    accessEnrollmentDetail() {
      this.selectEnrollment(this.enrollment);
      this.$router.push({
        name: 'AccessHistory',
        params: {
          enrollmentId: this.enrollment.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.enrollmentCard {
  min-height: 230px;

  .badge-pill {
    height: max-content;
  }
  .progress {
    border-radius: 8px;
  }
  p {
    color: $color-neutral-600;
  }

  .enrollment-action {
    color: $purple-dark;
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}
</style>
