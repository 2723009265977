<template>
  <b-modal
    size="md"
    centered
    hideHeader
    lazy
    body-class="px-4 py-4"
    :id="modalId"
    :ok-title="$t('sparkmembers.members-details.products.components.deactivate-enrollment-modal.buttons.confirm')"
    :cancel-title="$t('sparkmembers.members-details.products.components.deactivate-enrollment-modal.buttons.cancel')"
    @ok="updateEnrollment"
    @cancel="isActive = false"
  >
    <template v-if="isActive">
      <header class="text-center mb-4">
        <h3
          class="tlw-font-bold tlw-text-center tlw-text-xl tlw-mb-1"
          v-html="this.$t('sparkmembers.members-details.products.components.deactivate-enrollment-modal.title')"
        />
        <p v-html="this.$t('sparkmembers.members-details.products.components.deactivate-enrollment-modal.subtitle')" />
      </header>

      <div>
        <strong class="tlw-mb-1 tlw-block">{{
          $t('sparkmembers.members-details.products.components.deactivate-enrollment-modal.label')
        }}</strong>
        <b-form-select
          v-model="selectedProduct[0].id"
          :options="selectedProduct"
          value-field="id"
          text-field="title"
          disabled
        ></b-form-select>
      </div>
    </template>
    <hs-loading v-else />
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { hsLoading } from '@/components';

export default {
  components: {
    hsLoading,
  },
  props: {
    modalId: String,
  },
  data() {
    return {
      selectedProduct: [],
      isActive: false,
    };
  },
  computed: {
    ...mapState('enrollment', {
      enrollment: state => state.selectedEnrollment,
    }),
  },
  created() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'deactivate-enrollment-modal') {
        this.selectedProduct = [this.enrollment.school_product];
        this.isActive = true;
      }
    });
  },
  methods: {
    updateEnrollment() {
      this.$emit('confirmUpdateEnrollment');
    },
  },
};
</script>

<style scoped lang="scss">
.custom-select {
  background-image: none;
}
</style>
